import React, { useEffect, useRef, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'

import queryString from 'query-string'

import clsx from 'clsx'

import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { MIRROR } from '@/helpers/constants'
import { APMFlow, IAPM } from '@/api/settings'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import useFormStyles from '@/views/Exchange/forms/useFormStyles'
import { IRouteParams } from '@/types/route'
import { isNumericUrl } from '@/helpers/iframe'
import { setIsOBIBackgroundShown } from '@/store/actions/appData'

import Loader from '../Loader'

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '600px',
    border: 'none',
  },
})

interface IProps {
  paymentData: any;
  classes?: any;
  apm?: IAPM;
}

export default function PaymentFrame({ paymentData, classes, apm }: IProps) {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const localClasses = useStyles()
  const formClasses = useFormStyles()

  const history = useHistory()
  const { id } = useParams<IRouteParams>()

  const formRef = useRef<HTMLFormElement>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (paymentData?.id) {
      window.localStorage.setItem('paymentId', paymentData.id)
    }
  }, [paymentData?.id])

  useEffect(() => {
    if (apm?.flow !== APMFlow.REDIRECT) {
      return
    }

    if (isNumericUrl(paymentData.url)) {
      return history.push(`/order/${id}/${'pending'}${window.location.search}`)
    }

    return window.location.assign(paymentData.url)
  }, [paymentData.url, apm?.flow])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [formRef.current])

  useEffect(() => {
    if (apm?.flow === APMFlow.IFRAME && apm?.id === 'OBI') {
      dispatch(setIsOBIBackgroundShown(true))
    }
  }, [apm?.flow, apm?.id])

  if (!paymentData.id) {
    return <Loader loading height={600} />
  }

  if (!paymentData.url) {
    return (
      <Typography variant="body1" align="center">
        <p>{t('providerUnavailableMessage')}</p>
        <Link
          className={clsx(formClasses.send, 'wide')}
          component={Button}
          href={getSupportEmail(MIRROR)}
        >
          {t('Inform administrator')}
        </Link>
      </Typography>
    )
  }

  const paymentUrlParts = paymentData.url.split(' ');
  const paymentRequestType = ['GET', 'POST'].includes(paymentUrlParts[0]) ? paymentUrlParts[0] : 'GET';
  const paymentUrl = paymentUrlParts[paymentUrlParts.length - 1];
  let paymentRequest: any = {};
  if (paymentRequestType === 'POST') {
    paymentRequest = queryString.parse(paymentUrl.split('?')[1]);
  }

  return (
    <div>
      <Loader
        loading={isLoading}
        height={apm?.flow === APMFlow.REDIRECT ? 0 : 607}
      />
      {apm?.flow !== APMFlow.REDIRECT && (
        <div style={{ display: isLoading ? 'none' : 'block' }}>
          {paymentRequestType === 'GET' ? (
            <iframe
              id="payment"
              src={paymentUrl}
              title="Card form"
              className={classes ? classes.frame : localClasses.root}
              onLoad={() => setIsLoading(false)}
            />
          ) : (
            <form action={paymentUrl.split('?')[0]} method="POST" ref={formRef}>
              {Object.keys(paymentRequest).map((k: string) => (
                <input type="hidden" name={k} value={paymentRequest[k]} key={k} />
              ))}
            </form>
          )}
        </div>
      )}
    </div>
  )
}
