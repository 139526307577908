import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import subDays from 'date-fns/subDays'

import { SettlementFilterStatus } from '@/types/settlements'

export const SETTLEMENTS_FILTER_STATUSES = Object.values(SettlementFilterStatus)

export const EMPTY_SETTLEMENTS_FILTER = {
  dateFrom: startOfDay(subDays(new Date(), 6)).valueOf(),
  dateTo: endOfDay(new Date()).valueOf(),
  status: [],
}
