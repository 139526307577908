import { useEffect, useState } from 'react'

import { format } from 'date-fns'

import { IData } from '@/api'

type IFilter = Record<string, any>

interface IUseExportFileProps {
  exportedData: IData
  isExporting: boolean
  exportData: (filter: IFilter) => void
}

const useExportFile = ({
  exportData,
  isExporting,
  exportedData,
}: IUseExportFileProps) => {
  const [isShowExportDialog, setIsShowExportDialog] = useState(false)
  const [exportedFileName, setExportedFileName] = useState('')

  useEffect(() => {
    if (exportedFileName && !isExporting && exportedData) {
      setIsShowExportDialog(true)
    }
  }, [exportedData, exportedFileName, isExporting])

  const exportFilteredData = (filter: IFilter, parsedFilter?: IFilter) => {
    const dateFrom = format(filter.dateFrom, 'dd.MM.yyyy')
    const dateTo = format(filter.dateTo, 'dd.MM.yyyy')

    setExportedFileName(`settlements-${dateFrom}-${dateTo}`)

    exportData({
      filter: parsedFilter ? parsedFilter : filter,
      export: true,
    })
  }

  const downloadUrl = URL.createObjectURL(
    new Blob([exportedData?.data ? exportedData.data : ''])
  )

  const onCancelExport = () => {
    setTimeout(() => {
      setIsShowExportDialog(false)
      setExportedFileName('')
    }, 100)
  }

  return {
    downloadUrl,
    onCancelExport,
    exportedFileName,
    isShowExportDialog,
    exportFilteredData,
  }
}

export default useExportFile
