import { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'

import { setKycStatus } from '@/store/actions/routing'

import useUpdateApi, { useUpdateApiPromise } from '@/hooks/useUpdate'
import useFetchApi from '@/hooks/useFetch'

import Api, { usePrivateFetchApi } from './index'

const authWrapper = (cb: any) => {
  return (response: any) => {
    if (
      response.success &&
      response.data &&
      Object.keys(response.data).length
    ) {
      Sentry.setUser({
        id: response.data.id,
      })
    }
    cb(response)
  }
}

export function useAuth(cb: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/customer/auth', ...data),
    authWrapper(cb)
  )
}

export function useCodeResend(cb: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/customer/code', ...data),
    authWrapper(cb)
  )
}

export function useCustomerInfo(action?: any) {
  return usePrivateFetchApi({
    remote: async (...data: any) => Api.post('/customer/info', ...data),
    initialData: { data: { notLoaded: true } },
    dispatchAction: [action, setKycStatus],
    onErrorData: { data: { notLoaded: false } },
  })
}

export function useCustomerCountrySet(cb?: any) {
  return useUpdateApi<{ data: { success: boolean } }>(
    async (...data: any) => await Api.post('/customer/country', ...data),
    cb
  )
}

export function useCustomerStateSet(cb?: any) {
  return useUpdateApi<{ data: { success: boolean } }>(
    async (...data: any) => await Api.post('/customer/state', ...data),
    cb
  )
}

interface IUseCustomerBind {
  sid?: string
  externalId?: string
}

export function useCustomerBind({ sid, externalId }: IUseCustomerBind) {
  const [bindUserApi, isCustomerBinding] = useUpdateApiPromise<
    { externalId: string; sid: string },
    AxiosResponse
  >({
    remote: (...data) => {
      return Api.post('/customer/bind', ...data)
    },
  })

  const bindCustomer = async () => {
    if (sid && externalId) {
      await bindUserApi({ sid, externalId })
      return true
    }
    return false
  }

  return { bindCustomer, isCustomerBinding }
}

export function useCustomerPhoneSet(cb?: any) {
  return useUpdateApi<{ data: { success: boolean } }>(
    async (...data: any) => await Api.post('/customer/phone/set', ...data),
    cb
  )
}

export function useCustomerPhoneConfirm() {
  return useUpdateApiPromise<{ code: string }, { success: boolean }>({
    remote: (...data: any) => {
      return Api.post('/customer/phone/confirm', ...data)
    },
  })
}

export function useCustomerUsBilling() {
  return useUpdateApiPromise<{ phone: string; ssn: string }, AxiosResponse>({
    remote: (...args) => {
      return Api.post('/customer/billing/us', ...args)
    },
  })
}

export function useCustomerSSNSet(cb?: any) {
  return useUpdateApi<{ data: { success: boolean } }>(
    async (...data: any) => await Api.post('/customer/ssn', ...data),
    cb
  )
}

export function useCustomerBillingAddressSet(cb: any) {
  return useUpdateApi(
    async (...data: any) =>
      Api.post('/customer/billing/address/update', ...data),
    cb
  )
}

export function useCustomerWallet(cb: any) {
  return useUpdateApi(
    async (...data: any) => Api.post('/customer/auth/wallet', ...data),
    cb
  )
}

export const useCountryByIp = (loadOnInit?: boolean) => {
  const [response, countryByIpLoading, loadCountryByIp] = useFetchApi(
    async (...data: any) => {
      return Api.post('/customer/countryByIp', ...data)
    },
    { data: '' },
    undefined,
    loadOnInit
  )

  return [
    { data: response?.data?.countryByIp ?? '' },
    countryByIpLoading,
    loadCountryByIp,
  ] as [{ data: string }, boolean, () => void]
}
