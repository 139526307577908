import makeStyles from '@material-ui/core/styles/makeStyles'

const useOBICheckoutBackgroundStyles = makeStyles(() => ({
  root: {
    'backgroundColor': 'rgba(0, 0, 0, 0.25)',
    'width': '560px',
    'borderRadius': '8px',
    '@media (max-width: 599.95px)': {
      width: '100%',
      borderRadius: '0px 0px 8px 8px',
    },
  },
  wrapper: {
    'padding': '30px 32px 32px',
    'fontSize': '0.875rem',
    'fontWeight': 500,
    'zIndex': -1,
    'position': 'relative',
    'lineHeight': 1.5,
    'color': '#7D7E7F',
    'letterSpacing': '0.25px',
    '@media (max-width: 599.95px)': {
      padding: '14px 16px 16px',
      borderRadius: '0px 0px 8px 8px',
    },
  },
  header: {
    marginBottom: '24px',
  },
  title: {
    fontWeight: 600,
    fontSize: '1.5rem',
    margin: '0px 0px 2px',
    color: '#000',
  },
  subtitle: {
    margin: '0px 0px 16px',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  input: {
    border: '1px solid #e9e9e9',
    borderRadius: '60px',
    padding: '12px 13px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#f7f7f7',
    gap: '8px',
    marginBottom: '28px',
  },
  banks: {
    'display': 'grid',
    'gridTemplateColumns': 'repeat(4, 1fr)',
    'rowGap': '16px',
    'columnGap': '15px',
    'gridTemplateRows': 'repeat(2, auto)',
    'marginBottom': '28px',
    '@media (max-width: 599.95px)': {
      columnGap: '10px',
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
  bankIcon: {
    width: '58px',
    height: '58px',
  },
  bankWrapper: {
    'display': 'flex',
    'flexDirection': 'column',
    'gap': '6px',
    'fontWeight': 400,
    'alignItems': 'center',
    'maxWidth': '109px',
    'justifySelf': 'center',
    '@media (max-width: 599.95px)': {
      maxWidth: '71px',
    },
  },
  bankCaption: {
    'fontSize': '0.81rem',
    'textOverflow': 'ellipsis',
    'whiteSpace': 'nowrap',
    'overflow': 'hidden',
    'maxWidth': '109px',
    'display': 'inline-block',
    '@media (max-width: 599.95px)': {
      maxWidth: '71px',
    },
    '@media (max-width: 350.95px)': {
      maxWidth: '60px',
    },
  },
  bankSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '27px',
  },
  bankSelectTitle: {
    fontWeight: 600,
    fontSize: '1rem',
    margin: '0px',
    color: '#000',
  },
  expander: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '6px',
    alignItems: 'center',
  },
  country: {
    color: '#32bf6e',
  },
  bankOption: {
    'display': 'flex',
    'gap': '8px',
    'alignItems': 'center',
    '&:first-child': {
      marginBottom: '22px',
    },
  },
  bankOptionIcon: {
    width: '42px',
    height: '42px',
  },
  bankOptionCaption: {
    fontWeight: 600,
    fontSize: '1rem',
    color: '#000',
  },
  toggleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '17px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    alignItems: 'center',
  },
  footerItem: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}))

export default useOBICheckoutBackgroundStyles
