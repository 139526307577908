import React, { useEffect, FC } from 'react'

import makeStyles from '@material-ui/styles/makeStyles/makeStyles'
import Typography from '@material-ui/core/Typography'

import earthIcon from '../../../icons/earth.svg'

interface IBaseRestrictedScreenProps {
  title: string
}

const BaseRestrictedScreen: FC<IBaseRestrictedScreenProps> = ({
  title,
  children,
}) => {
  const localClasses = useStyles()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={localClasses.root}>
      <Typography variant="h6" align="center" className={localClasses.title}>
        {title}
      </Typography>
      {children}
    </div>
  )
}

export default BaseRestrictedScreen

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 130,
    background: `url("${earthIcon}") no-repeat center top`,
  },
  title: {
    marginBottom: '24px !important',
  },
  info: {
    marginBottom: '48px !important',
  },
}))
