import React, { useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import DatePicker from 'react-datepicker'

import clsx from 'clsx'

import {
  Button,
  Chip,
  ClickAwayListener,
  DialogActions,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useGlobalStyles from '@/hooks/useGlobalStyles'
import { useFilterStyles } from '@/hooks/useFilter'
import {
  EMPTY_SETTLEMENTS_FILTER,
  MONTH_START,
  SETTLEMENTS_FILTER_STATUSES,
  TODAY_END,
  TODAY_START,
  WEEK_START,
  YESTERDAY_END,
  YESTERDAY_START,
} from '@/constants'
import DateView from '@/components/DateView'
import { getMaxDate } from '@/helpers/dateFilter'
import { changeStatusFilter, parseStatusFilter } from '@/helpers/settlements'
import { getChipColor } from '@/helpers/getChipColor'
import useChangeDateFilter from '@/hooks/useChangeDateFilter'

import 'react-datepicker/dist/react-datepicker.css'
import { SettlementFilterStatus } from '@/types/settlements'

const useStyles = makeStyles((theme) => ({
  filters: {
    marginBottom: theme.spacing(4),
    transition: 'height 0.3s ease',
    overflow: 'hidden',
  },
  button: {
    width: '100%',
    margin: '0px',
    height: '52px',
  },
  actions: {
    padding: '0px',
    display: 'block',
  },
  actionsButtons: {
    columnGap: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '244px 244px',
  },
  cancelButton: {
    'backgroundColor': 'rgba(125, 147, 168, 0.08)',
    'color': 'rgba(0, 0, 0, 1)',
    '&:hover': {
      backgroundColor: 'rgba(125, 147, 168, 0.12)',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(125, 147, 168, 0.08)',
      color: 'rgba(0, 0, 0, 1)',
    },
  },
}))

type IPayload = {
  filter: Record<string, any>
  cursor: number
}

interface ISettlementsFilters {
  load: (payload: IPayload) => void
  isShowFilters: boolean
  filter: Record<string, any>
  setFilter: (filter: Record<string, any>) => void
}

function SettlementsFilters({
  load,
  filter,
  setFilter,
  isShowFilters,
}: ISettlementsFilters) {
  const { t } = useTranslation()

  const { user } = useSelector((state: any) => state.account)

  const globalClasses = useGlobalStyles()
  const localClasses = useStyles()
  const filterClasses = useFilterStyles()

  const filterRef = useRef<HTMLDivElement>(null)

  const [datePicker, setDatePicker] = useState(false)

  const {
    endDate,
    startDate,
    isCustomDates,
    setEndDate,
    setStartDate,
    setIsCustomDates,
    changeDateFilter,
  } = useChangeDateFilter({ filter, setFilter })

  useEffect(() => {
    if (!filterRef.current) {
      return
    }

    if (isShowFilters) {
      const scrollHeight = filterRef.current.scrollHeight + 50
      filterRef.current.style.height = `${scrollHeight}px`
    } else {
      filterRef.current.style.height = '0px'
    }
  }, [isShowFilters])

  return (
    <div className={localClasses.filters} ref={filterRef}>
      <div className={filterClasses.filter}>
        <Typography variant="h6">{t('Date range')}</Typography>
        <div className={filterClasses.optionsDate}>
          <div className={filterClasses.chips}>
            <Chip
              label={t('Today')}
              onClick={() =>
                changeDateFilter({ dateFrom: TODAY_START, dateTo: TODAY_END })
              }
              color={getChipColor(filter, TODAY_START, TODAY_END)}
            />
            <Chip
              label={t('Yesterday')}
              onClick={() =>
                changeDateFilter({
                  dateFrom: YESTERDAY_START,
                  dateTo: YESTERDAY_END,
                })
              }
              color={getChipColor(filter, YESTERDAY_START, YESTERDAY_END)}
            />
            <Chip
              label={t('Last week')}
              onClick={() =>
                changeDateFilter({ dateFrom: WEEK_START, dateTo: TODAY_END })
              }
              color={getChipColor(filter, WEEK_START, TODAY_END)}
            />
            <Chip
              label={t('Last month')}
              onClick={() =>
                changeDateFilter({ dateFrom: MONTH_START, dateTo: TODAY_END })
              }
              color={getChipColor(filter, MONTH_START, TODAY_END)}
            />
          </div>
          <div className={filterClasses.datepicker}>
            <Chip
              label={
                isCustomDates && endDate !== null ? (
                  <span>
                    <DateView value={startDate} timeless /> -{' '}
                    <DateView value={endDate} timeless />
                  </span>
                ) : (
                  t('Custom range')
                )
              }
              onClick={() => {
                setDatePicker(!datePicker)
                if (!isCustomDates) {
                  setStartDate(new Date())
                  setEndDate(new Date())
                }
              }}
              color={isCustomDates || datePicker ? 'primary' : 'default'}
            />
            {datePicker && (
              <ClickAwayListener onClickAway={() => setDatePicker(!datePicker)}>
                <div className="wrapper">
                  <DatePicker
                    selected={startDate}
                    onChange={(range) => {
                      if (Array.isArray(range)) {
                        const [start, end] = range
                        if (!end) {
                          setStartDate(start)
                          setEndDate(null)
                        } else {
                          changeDateFilter({
                            dateFrom: start.valueOf(),
                            dateTo: end.valueOf(),
                            isCustom: true,
                          })
                          setDatePicker(false)
                        }
                      }
                    }}
                    minDate={new Date(user.createdAt)}
                    maxDate={getMaxDate(startDate, endDate)}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </div>
      <div className={filterClasses.filter}>
        <Typography variant="h6">{t('Status')}</Typography>
        <div className={filterClasses.options}>
          {SETTLEMENTS_FILTER_STATUSES.map((status) => (
            <Chip
              label={status.charAt(0) + status.slice(1).toLowerCase()}
              onClick={() =>
                changeStatusFilter(status, filter.status, setFilter)
              }
              color={
                filter.status && filter.status.includes(status)
                  ? 'primary'
                  : 'default'
              }
              key={status}
            />
          ))}
        </div>
      </div>
      <DialogActions disableSpacing={true} className={localClasses.actions}>
        <div className={localClasses.actionsButtons}>
          <Button
            className={clsx(
              globalClasses.button,
              localClasses.button,
              localClasses.cancelButton
            )}
            color="primary"
            variant="contained"
            onClick={() => {
              setFilter({ ...EMPTY_SETTLEMENTS_FILTER, status: [] })
              load({
                filter: {
                  ...EMPTY_SETTLEMENTS_FILTER,
                  status: [],
                  customerIds: [user.id],
                },
                cursor: 0,
              })
              setIsCustomDates(false)
            }}
            id="clear-settlement"
          >
            {t('Clear')}
          </Button>
          <Button
            className={clsx(globalClasses.button, localClasses.button)}
            color="primary"
            variant="contained"
            id="apply-settlement"
            onClick={() =>
              load({
                filter: {
                  ...filter,
                  customerIds: [user.id],
                  status: parseStatusFilter(filter.status),
                },
                cursor: 0,
              })
            }
          >
            {t('Apply')}
          </Button>
        </div>
      </DialogActions>
    </div>
  )
}

export default SettlementsFilters
