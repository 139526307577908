import { PrivateApi, usePrivateFetchApi } from '../index'
import useUpdateApi from '../../hooks/useUpdate'

export function useBalances() {
  return usePrivateFetchApi({
    remote: async (...data: any) =>
      PrivateApi.post('/account/balances', ...data),
    initialData: { items: [] },
  })
}

export function useSetResidence(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/account/country', ...data),
    cb
  )
}

export function useAcceptTerms(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/account/terms', ...data),
    cb
  )
}

export function useAcceptRiskDisclosure(
  cb: (res: Record<string, any>) => void
) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/account/risks', ...data),
    cb
  )
}

export function useReferralBonusList() {
  return usePrivateFetchApi({
    remote: async (...data: any) =>
      PrivateApi.post('/account/referral/bonus', ...data),
    initialData: { items: [] },
  })
}

export function useSettlementBalancesList() {
  return usePrivateFetchApi({
    remote: async (...data: any) =>
      PrivateApi.post('/settlement/balances/list', ...data),
    initialData: { items: [] },
  })
}

export function useSettlementsList() {
  return usePrivateFetchApi({
    remote: async (...data: any) =>
      PrivateApi.post('/settlement/find', ...data),
    initialData: { items: [] },
  })
}

export function useSettlementsExport() {
  return usePrivateFetchApi({
    remote: async (...data: any) =>
      PrivateApi.post('/settlement/export', ...data),
    initialData: { items: [], cursor: 0 },
    exportData: true,
  })
}

export function useSettlementInit(cb: any) {
  return useUpdateApi(
    async (...data: any) => PrivateApi.post('/settlement/init', ...data),
    cb
  )
}
