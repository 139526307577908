import { IOrder } from '@/api/order'
import { IAPM } from '@/api/settings'

import { VIEW_FOR_APMS, isSf } from './constants'
import apmMap from './apmMap'

export function getApmView(step: string | undefined, order?: IOrder) {
  if (
    order?.apm &&
    VIEW_FOR_APMS.includes(order?.apm) &&
    ((isSf && (step === 'card' || step === 'transaction')) ||
      (order?.sf && step === 'card'))
  ) {
    return true
  }
  return false
}

export function getApmImageSrc(apm: IAPM | undefined) {
  if (apm?.name && apm.name.toLowerCase().includes('revolut')) {
    return '/img/apm/revolut.png'
  }

  if (apm?.logo) {
    return apm.logo
  }

  if (apm?.id && apm.id !== 'CARD') {
    if (apm.id === 'OBI') {
      return `/img/apm/newOBI_v2.svg`
    }
    if (apm.id === 'BLIK') {
      return `/img/apm/newBLIK_v2.svg`
    }
    return `/img/apm/new${apm.id}.svg`
  }

  return apmMap['NoLogo'].logo
}
