import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import makeStyles from '@material-ui/styles/makeStyles'

import { ISettlement } from '@/types/settlements'
import DateView from '@/components/DateView'
import { getTxHash } from '@/helpers/settlements'

import SettlementStatusView from './SettlementStatusView'
import SettlementDetails from './Details/SettlementDetails'
import useGlobalStyles from '../../../hooks/useGlobalStyles'

const useStyles = makeStyles(() => ({
  tableCellStatus: {
    paddingTop: '12.6px',
    paddingBottom: '11.4px',
  },
  row: {
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
}))

interface IDesktopSettlementsList {
  settlements: ISettlement[]
}

function DesktopSettlementsList({ settlements }: IDesktopSettlementsList) {
  const { t } = useTranslation()

  const { user } = useSelector((state: any) => state.account)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [settlementDetails, setSettlementDetails] = useState({})

  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const openDetails = (settlement: ISettlement) => {
    setSettlementDetails(settlement)
    setIsOpenModal(true)
  }

  return (
    <>
      <div className={globalClasses.desktopShow}>
        <Table className={globalClasses.tableSimple}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" component="span">
                  {t('settlements.requestId')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="span">
                  {t('settlements.requested')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="span">
                  {t('settlements.updated')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="span">
                  {t('settlements.status')}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" component="span">
                  {t('settlements.hash')}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" component="span">
                  {t('settlements.reqAmount')}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" component="span">
                  {t('settlements.settlementAmount')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settlements.map((settlement) => (
              <TableRow
                key={settlement.id}
                className={classes.row}
                onClick={() => {
                  if (user?.isMaster) {
                    openDetails(settlement)
                  }
                }}
              >
                <TableCell>
                  <Typography variant="body2" component="span">
                    {settlement.id}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" component="div">
                    <DateView value={settlement.createdAt} />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" component="div">
                    <DateView value={settlement.updatedAt} />
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCellStatus}>
                  <Typography variant="body2" component="div">
                    <SettlementStatusView status={settlement.status} />
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCellStatus}>
                  <Typography variant="body2" component="span">
                    {getTxHash(settlement?.blockchainTxId)}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" component="div">
                    {Object.entries(settlement.operations).map(([id, data]) => (
                      <div key={id}>
                        {Object.entries(data).map(([currency, amount]) => (
                          <Typography
                            key={currency}
                            component="h1"
                            variant="body2"
                          >
                            {amount.toFixed(2)} {currency}
                          </Typography>
                        ))}
                      </div>
                    ))}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body2" component="span">
                    {settlement?.settlementAmount
                      ? `${settlement.settlementAmount} ${settlement.settlementCurrency}`
                      : '—'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SettlementDetails
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        settlement={settlementDetails}
      />
    </>
  )
}
export default DesktopSettlementsList
