import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import BaseRestrictedScreen from './BaseRestrictedScreen'
import { useLoginStyles } from '../Login'
import { trackAddCor } from '@/helpers/analytics'
import { IOrder, OrderStatus } from '@/api/order'
import { useOrderCancel } from '@/api/private/orders'
import { getSupportEmail } from '@/helpers/mirrorHelper'
import { isSf, MIRROR } from '@/helpers/constants'

interface IResidenceRestrictedProps {
  logout?(): void
  order?: IOrder
  country?: string
  canCancelOrder?: boolean
}

const ResidenceRestricted: FC<IResidenceRestrictedProps> = ({
  logout,
  order,
  country,
  canCancelOrder = false,
}) => {
  const { t } = useTranslation()
  const classes = useLoginStyles()

  useEffect(() => {
    trackAddCor(
      'view_residence_country_error',
      order,
      country,
      'onramp_personal_info'
    )
  }, [])

  const [cancelOrder] = useOrderCancel(() => null)

  useEffect(() => {
    if (
      order?.status !== OrderStatus.ERROR &&
      order?.id &&
      isSf &&
      canCancelOrder
    ) {
      cancelOrder({ id: order.id })
    }
  }, [order?.status, order?.id, isSf, canCancelOrder])

  return (
    <BaseRestrictedScreen title={t('validation.countryNotSupported')}>
      {logout && (
        <p>
          <Button
            onClick={logout}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            {t('Return to log in page')}
          </Button>
        </p>
      )}
      <p>
        <Link
          className={clsx(classes.button, 'text')}
          component={Button}
          href={getSupportEmail(MIRROR)}
        >
          {t('Contact support')}
        </Link>
      </p>
    </BaseRestrictedScreen>
  )
}

export default ResidenceRestricted
