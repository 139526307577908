import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import subDays from 'date-fns/subDays'
import subMonths from 'date-fns/subMonths'
import subWeeks from 'date-fns/subWeeks'

export const TODAY = new Date()
export const TODAY_START = startOfDay(TODAY).valueOf()
export const TODAY_END = endOfDay(TODAY).valueOf()
export const YESTERDAY_START = startOfDay(subDays(TODAY, 1)).valueOf()
export const YESTERDAY_END = endOfDay(subDays(TODAY, 1)).valueOf()
export const WEEK_START = startOfDay(subWeeks(TODAY, 1)).valueOf()
export const MONTH_START = startOfDay(subMonths(TODAY, 1)).valueOf()
export const START_OF_LAST_SEVEN_DAYS = startOfDay(subDays(new Date(), 6))
