type IDateFilter = {
  dateFrom?: number
  dateTo?: number
}

export const getChipColor = (
  filter: IDateFilter,
  startDate: number,
  endDate: number
) => {
  if (filter?.dateFrom === startDate && filter?.dateTo === endDate) {
    return 'primary'
  }

  return 'default'
}
