import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'

import { useSelector } from 'react-redux'

import { Waypoint } from 'react-waypoint'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Button, IconButton } from '@material-ui/core'

import { useSettlementsExport, useSettlementsList } from '@/api/private/account'
import useFilter from '@/hooks/useFilter'
import { EMPTY_SETTLEMENTS_FILTER } from '@/constants'
import useExportFile from '@/hooks/useExportFile'
import { parseStatusFilter } from '@/helpers/settlements'

import ExportHistoryModal from './History/ExportHistoryModal'
import SettlementsFilters from './Filters/SettlementsFilters'
import DesktopSettlementsList from './DesktopSettlementsList'
import MobileSettlementsList from './MobileSettlementsList'
import useGlobalStyles from '../../../hooks/useGlobalStyles'
import Loader from '../../../components/Loader'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
      marginBottom: theme.spacing(2),
    },
  },
  button: {
    height: 36,
    width: 'auto',
  },
  dashboard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}))

function Settlements() {
  const { t } = useTranslation()

  const { user } = useSelector((state: any) => state.account)

  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const history = useHistory()

  const [exportedData, isExporting, exportData] = useSettlementsExport()
  const [{ items: settlements, full, error }, loading, load] =
    useSettlementsList()

  const [isShowFilters, setIsShowFilters] = useState(false)

  const [setFilter, filter] = useFilter('settlements', EMPTY_SETTLEMENTS_FILTER)

  useEffect(() => {
    setFilter({ ...EMPTY_SETTLEMENTS_FILTER, status: [] })
  }, [])

  const {
    downloadUrl,
    onCancelExport,
    exportedFileName,
    isShowExportDialog,
    exportFilteredData,
  } = useExportFile({ exportData, isExporting, exportedData })

  useEffect(() => {
    if (user.id) {
      if (!user?.authorities.includes('ROLE_MERCHANT')) {
        history.push('/account/info')
      } else {
        load({
          filter: { ...filter, customerIds: [user.id] },
          cursor: 0,
        })
      }
    }
  }, [user])

  return (
    <>
      <ExportHistoryModal
        href={downloadUrl}
        onClose={onCancelExport}
        isOpen={isShowExportDialog}
        exportedFileName={exportedFileName}
      />
      <div className={classes.dashboard}>
        <Typography
          variant="h4"
          className={clsx(globalClasses.title, classes.title)}
        >
          {t('settlements.title')}
        </Typography>
        <div className={classes.wrapper}>
          <div>
            <IconButton onClick={() => setIsShowFilters(!isShowFilters)}>
              <FilterListIcon />
            </IconButton>
          </div>
          <Button
            variant="contained"
            disabled={isExporting}
            onClick={() =>
              exportFilteredData(filter, {
                ...filter,
                status: parseStatusFilter(filter.status),
              })
            }
            className={clsx(globalClasses.button, classes.button)}
            color="primary"
          >
            {t('Export')}
          </Button>
        </div>
      </div>
      <SettlementsFilters
        load={load}
        filter={filter}
        setFilter={setFilter}
        isShowFilters={isShowFilters}
      />
      {settlements.length > 0 && (
        <DesktopSettlementsList settlements={settlements} />
      )}
      {settlements.length > 0 && (
        <MobileSettlementsList settlements={settlements} />
      )}
      {settlements.length === 0 && !loading && (
        <Typography variant="body1" align="center">
          {t('settlements.empty')}
        </Typography>
      )}
      <Loader
        loading={loading}
        height={settlements.length > 0 ? 100 : undefined}
      />
      {!loading && !error && !full && (
        <Waypoint
          onEnter={() =>
            load({
              filter: {
                ...filter,
                status: parseStatusFilter(filter.status),
                customerIds: [user.id]
              },
              cursor: settlements.length,
            })
          }
        />
      )}
    </>
  )
}
export default Settlements
