import {
  ICheckedSidRequest,
  ICheckedSubaccountRequest,
  ISubaccountRequestInfo,
  SettlementFilterStatus,
} from '@/types/settlements'

type IFormSubaccountData = Record<string, ISubaccountRequestInfo>

type IFormMasterData = Record<string, Record<string, any>>

export const checkAvailableRequests = (
  values: IFormSubaccountData,
  checkedRequests: ICheckedSubaccountRequest[]
) => {
  Object.entries(values).map(([currency, request]) => {
    if (
      request?.checked &&
      request?.reqAmount &&
      isFinite(request.reqAmount) &&
      request.reqAmount > 0
    ) {
      checkedRequests.push({
        currency,
        amount: Number(request.reqAmount),
      })
    }
    return
  })
}

export const getAmount = (input: number, available: number) => {
  return Number((input * available) / 100).toFixed(2)
}

export const getPercentage = (input: number, available: number) => {
  return Math.round((input * 100) / available)
}

export const isSelectedSid = (name: string, selectedSids: string[]) => {
  return selectedSids.indexOf(name) !== -1
}

export const checkAvailableSidRequests = (
  values: IFormMasterData,
  checkedSidRequests: ICheckedSidRequest[]
) => {
  Object.entries(values).map(([sid, request]) => {
    if (
      request?.checked &&
      request?.reqAmount > 0 &&
      request?.reqAmount <= request?.availableBalance
    ) {
      checkedSidRequests.push({
        sid,
        amount: Number(request.reqAmount),
      })
    }
    return
  })
}

export const getTxHash = (hash?: string) => {
  if (!hash) {
    return '—'
  }

  if (hash.length > 9) {
    return hash.slice(0, 9) + '...'
  }

  return hash
}

export const changeStatusFilter = (
  status: SettlementFilterStatus,
  statuses: SettlementFilterStatus[],
  setFilter: (filter: Record<string, any>) => void
) => {
  const existIndex = statuses.indexOf(status)

  if (existIndex !== -1) {
    statuses.splice(existIndex, 1)
    setFilter({ status: statuses })
    return
  }

  if (status === SettlementFilterStatus.ALL) {
    setFilter({ status: [SettlementFilterStatus.ALL] })
    return
  }

  const allStatusIndex = statuses.indexOf(SettlementFilterStatus.ALL)

  if (allStatusIndex !== -1) {
    statuses.splice(allStatusIndex, 1)
  }

  statuses.push(status)
  setFilter({ status: statuses })
  return
}

export const parseStatusFilter = (statuses: string[]) => {
  if (statuses.includes(SettlementFilterStatus.ALL)) {
    return []
  }

  return statuses
}
