import { useEffect, useState } from 'react'

import { MONTH_START, START_OF_LAST_SEVEN_DAYS, TODAY_END } from '@/constants'
import { IDateFilter } from '@/types'

interface IUseChangeDateFilterProps {
  filter: Record<string, any>
  setFilter: (filter: Record<string, any>) => void
}

const useChangeDateFilter = ({
  filter,
  setFilter,
}: IUseChangeDateFilterProps) => {
  const [startDate, setStartDate] = useState(new Date(MONTH_START))
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [isCustomDates, setIsCustomDates] = useState(false)

  useEffect(() => {
    setStartDate(new Date(filter.dateFrom))
    setEndDate(new Date(filter.dateTo))
  }, [filter.dateFrom, filter.dateTo])

  function changeDateFilter({ dateFrom, dateTo, isCustom }: IDateFilter) {
    if (dateFrom === null) {
      setFilter({
        dateFrom: START_OF_LAST_SEVEN_DAYS.valueOf(),
        dateTo: TODAY_END,
      })
    } else {
      setFilter({ dateFrom, dateTo })
    }

    setIsCustomDates(!!isCustom)
  }

  return {
    endDate,
    startDate,
    isCustomDates,

    setEndDate,
    setStartDate,
    setIsCustomDates,
    changeDateFilter,
  }
}

export default useChangeDateFilter
