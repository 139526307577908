export enum SettlementStatus {
  SETTLED = 'SETTLED',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
}

export type ISettlementOperations = Record<number, Record<string, number>>

export interface ISettlement {
  id: number
  createdAt: number
  updatedAt: number
  status: SettlementStatus
  customerId: number
  settlementAmount?: number
  settlementCurrency?: string
  requestedEurAmount: string
  blockchainTxId?: string
  operations: ISettlementOperations
}

export interface ISettlementBalance {
  id: number
  createdAt: number
  updatedAt: number
  currency: string
  balance: number
  locked: number
  customerId: number
  sid: string
  email: string
  legalEntity: string
  isMaster: boolean
}

export type ISettlementRequestType = {
  settlementsData: Record<string, Record<string, number>>
  customerId: string
}

export interface ICheckedSubaccountRequest {
  currency: string
  amount: number
}

export interface ISubaccountRequestInfo {
  checked?: boolean
  reqAmount?: number
  percentage?: number
}

export interface ICheckedSidRequest {
  sid: string
  amount: number
}

export enum SettlementFilterStatus {
  ALL = 'ALL',
  SETTLED = 'SETTLED',
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
}
