import addDays from 'date-fns/addDays'

import { TODAY } from '@/constants'

export const getMaxDate = (startDate: Date, endDate: Date | null) => {
  if (endDate) {
    return TODAY
  }

  if (addDays(startDate, 30).valueOf() > TODAY.valueOf()) {
    return TODAY
  }

  return addDays(startDate, 30)
}
