import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
// import Big from 'big.js';
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check'
import InputAdornment from '@material-ui/core/InputAdornment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IAPM } from '@/api/settings'
import { usePopupSelectStyles } from './Residence'

import { ILangType } from '@/locales'
import { qaAttr } from '@/helpers/qaHelpers'
import {
  trackViewCheckoutScreen,
  trackAddPaymentMethod,
} from '@/helpers/analytics'
import { getApmView } from '@/helpers/getApmView'
import subtituteOBI from '@/config/substituteOBI.json'
import OBI from '@/icons/apm/OBI.svg'
import { isChainValley } from '@/helpers/mirrorHelper'
import { getCardOption } from '@/hooks/payment/useAPM'
import { getIsOBIBackground } from '@/helpers/getIsOBIBackground'

const env = process.env.REACT_APP_WIDGET_ENV || 'dev'

interface IProps {
  apms: IAPM[]
  language: ILangType
  classes: any
  apm?: string
  select(value: string): any
  loading: boolean
  error: boolean
  hideCard: boolean
  apmLocked: boolean
  onEmpty(): void
  order?: any
  country?: string
  step: string
  apmsLoaded?: boolean
}

export default function APMSelect({
  apms,
  language,
  apm,
  select,
  classes,
  loading,
  error,
  hideCard,
  apmLocked,
  onEmpty,
  order,
  country,
  step,
  apmsLoaded,
}: IProps) {
  const { t } = useTranslation()
  const localClasses = usePopupSelectStyles()
  const cardOption = getCardOption(t)
  const [options, setOptions] = useState<IAPM[]>([cardOption])
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const inputRef = useRef<any>(null)

  useEffect(() => {
    trackViewCheckoutScreen(
      'view_checkout_screen',
      order,
      country,
      'payment method'
    )
  }, [])

  useEffect(() => {
    if (apmLocked) {
      if (apms.length) {
        const option = apms.filter((i) => i.id == apm)
        if (!option.length) {
          onEmpty()
        }
        setOptions(option)
      } else if (apmsLoaded) {
        onEmpty()
      }
    } else if (apms.length) {
      if (hideCard) {
        setOptions(apms)
      } else {
        setOptions([cardOption, ...apms])
      }
    } else {
      setOptions([cardOption])
    }
  }, [apms, language, apmsLoaded])

  function closeModal() {
    setSearch(undefined)
    setOpen(false)
  }

  const selected = options.find((o) => o.id === apm)

  // const feeView = (apm: IAPM) => {
  //   const { fee: { depositFixed, deposit, depositFixedCurrency } } = apm;
  //   const depositStr = deposit ? Big(deposit).times(100).toString() + '%' : '';
  //   const fixedStr = depositFixed ? (deposit ? '+' : '') + `${depositFixed} ${depositFixedCurrency}` : '';
  //   return depositFixed || deposit ? `(${t('Method fee')} ${depositStr}${fixedStr})` : '';
  // }

  useEffect(() => {
    if (!selected) {
      return
    }
    if (inputRef.current === null) {
      return
    }

    // Disabled Fee
    // inputRef.current.value = `${selected.name} ${feeView(selected)}`;
    inputRef.current.value = `${selected.name}`
  }, [inputRef.current, selected])

  const showApmView = getApmView(step, order)

  const endAdornment = loading ? (
    <InputAdornment position="end">
      <CircularProgress size={22} color="inherit" />
    </InputAdornment>
  ) : options.length > 1 ? (
    <InputAdornment position="end">
      <ExpandMoreIcon />
    </InputAdornment>
  ) : undefined

    // @ts-ignore
    const swapIcon = subtituteOBI.showForMerchants[env]?.includes(
      order.mId
    )

  const isOBIBackground = getIsOBIBackground(order, showApmView)

  const changeToOBI = swapIcon && country === 'DE' && apm === 'OBI'
  const isCCWChainValley = showApmView && isChainValley

  return (
    <div className={clsx(localClasses.root, showApmView && 'divider')}>
      {open && (
        <Dialog open onClose={closeModal}>
          <div
            className={clsx(classes.dialog, localClasses.apms)}
            style={{ height: options.length * 48 + 155 }}
          >
            <div className={classes.dialogHeader}>
              <Typography component="div" className={classes.dialogTitle}>
                {t('Select payment method')}
              </Typography>
              <TextField
                className={classes.field}
                placeholder={`${t('Search')}...`}
                fullWidth
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ paddingLeft: 16 }}
                    >
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={clsx(classes.dialogList, 'apms')}>
              {options
                .filter((o) =>
                  search?.length
                    ? o.name.toLowerCase().includes(search.toLowerCase())
                    : true
                )
                .map((o) => (
                  <div
                    className={clsx(localClasses.option, {
                      active: apm === o.id,
                    })}
                    onClick={() => {
                      trackAddPaymentMethod('add_payment_method', order, o.name)
                      select(o.id)
                      setOpen(false)
                    }}
                    key={o.id}
                  >
                    <div className="details">
                      <div className={localClasses.apmAdornment}>
                        <img
                          src={o.logo}
                          alt={o.name}
                          className={localClasses.apmImage}
                        />
                      </div>
                      <Typography variant="body2">{o.name}&nbsp;</Typography>
                      {/*  Disabled Fee */}
                      {/* {(o.fee.depositFixed > 0 || o.fee.deposit > 0) && (
                        <Typography variant="body2" color="textSecondary">
                          {feeView(o)}
                        </Typography>
                      )} */}
                    </div>
                    <div className="details">
                      {apm === o.id && (
                        <CheckIcon color="inherit" className="check" />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Dialog>
      )}
      {!isCCWChainValley && !isOBIBackground && (
        <div
          className={clsx(
            classes.blockTitle,
            changeToOBI && classes.bold,
            showApmView && 'center'
          )}
        > 
          {changeToOBI ? t('Bank Transfer') : t('Payment Method')}
        </div>
      )}
      {!showApmView && (
        <TextField
          className={clsx(classes.field, localClasses.apmField, 'select')}
          name="country"
          variant="filled"
          onClick={() => options.length > 1 && setOpen(true)}
          inputRef={inputRef}
          hiddenLabel
          fullWidth
          disabled={options.length < 2}
          error={error}
          helperText={error ? t('Please select payment method') : ''}
          placeholder={t('Select the payment method')}
          inputProps={qaAttr('payment-method-select')}
          InputProps={{
            readOnly: true,
            endAdornment,
            startAdornment: selected ? (
              <InputAdornment position="start">
                <div className={localClasses.apmAdornment}>
                  <img
                    src={selected.logo}
                    alt={selected.name}
                    className={localClasses.apmImage}
                  />
                </div>
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
      {showApmView && !isChainValley && !isOBIBackground && (
        <div className={localClasses.apmWrapper}>
          <div className={localClasses.ccwOrderImage}>
            {' '}
            {changeToOBI ? (
              <img src={OBI} alt="OBI" className={localClasses.iconOBI} />
            ) : (
              <img
                src={selected?.logo ?? '/img/apm/static/NoLogo.svg'}
                alt="Apm"
                className={localClasses.ccwImage}
              />
            )}
          </div>
        </div>
      )}
      {isCCWChainValley && <div className={localClasses.emptyBlock} />}
    </div>
  )
}
