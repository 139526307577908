import React from 'react'

import { Card } from '@material-ui/core'

import useOBICheckoutBackgroundStyles from './OBICheckoutBackgroundStyles'
import searchIcon from './images/search.svg'
import revolut from './images/revolut.svg'
import bbva from './images/bbva.svg'
import wise from './images/wise.svg'
import n26 from './images/n26.svg'
import paribas from './images/paribas.svg'
import santander from './images/santander.svg'
import raiffeisen from './images/raiffeisen.svg'
import unicredit from './images/unicredit.svg'
import arrowIcon from './images/arrow.svg'
import toggleIcon from './images/toggle.svg'
import globeIcon from './images/globe.svg'
import helpIcon from './images/help.svg'

interface IBank {
  icon: string
  caption: string
}

const SELECTABLE_BANKS = [
  {
    icon: revolut,
    caption: 'Revolut',
  },
  {
    icon: wise,
    caption: 'Wise',
  },
]

const BANKS = [
  {
    icon: revolut,
    caption: 'Revolut',
  },
  {
    icon: wise,
    caption: 'Wise',
  },
  {
    icon: n26,
    caption: 'N26',
  },
  {
    icon: bbva,
    caption: 'BBVA',
  },
  {
    icon: paribas,
    caption: 'BNP Paribas',
  },
  {
    icon: santander,
    caption: 'Santander',
  },
  {
    icon: raiffeisen,
    caption: 'Raiffeisen',
  },
  {
    icon: unicredit,
    caption: 'Unicredit',
  },
]

function OBICheckoutBackground() {
  const classes = useOBICheckoutBackgroundStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.wrapper}>
        <div className={classes.header} />
        <div>
          <h3 className={classes.title}>Select your bank</h3>
          <h5 className={classes.subtitle}>
            We’ll connect you to your bank to approve the payment.
          </h5>
        </div>
        <div className={classes.input}>
          <img src={searchIcon} />
          <span>Search for your bank</span>
        </div>
        <div className={classes.banks}>
          {BANKS.map((bank: IBank) => (
            <div key={bank.caption} className={classes.bankWrapper}>
              <img src={bank.icon} className={classes.bankIcon} />
              <span className={classes.bankCaption}>{bank.caption}</span>
            </div>
          ))}
        </div>
        <div className={classes.bankSelect}>
          <h4 className={classes.bankSelectTitle}>All banks</h4>
          <div className={classes.expander}>
            <div className={classes.country}>Finland</div>
            <img src={arrowIcon} />
          </div>
        </div>
        <div>
          {SELECTABLE_BANKS.map((bank: IBank) => (
            <div key={bank.caption} className={classes.bankOption}>
              <img src={bank.icon} className={classes.bankOptionIcon} />
              <span className={classes.bankOptionCaption}>{bank.caption}</span>
            </div>
          ))}
        </div>
        <div className={classes.toggleWrapper}>
          <span>Remember my details. Terms of use</span>
          <img src={toggleIcon} />
        </div>
        <div className={classes.footer}>
          <div className={classes.footerItem}>
            <img src={globeIcon} />
            <span>English</span>
          </div>
          <div className={classes.footerItem}>
            <img src={helpIcon} />
            <span>Get help</span>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default OBICheckoutBackground
