import { useState, useRef, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { IOrder, OrderProcessingError } from '../../api/order'
import {
  PaymentNext,
  useWaterfallPaymentInit,
  usePaymentStatusApi,
  useWaterfallPaymentInitMobile,
  initPaymentPayload,
} from '../../api/payment'
import {
  useCardsList,
  usePrivatePaymentMobile,
  usePrivatePaymentPay,
  usePrivatePaymentPayNew,
  useThreeDSGet,
} from '../../api/private/payment'
import { I3DsForm, IFingerprintForm, IPayResponse } from '@/types/payment'

interface IPaymentHook {
  initPayment(data: any): void
  pay(data: any): void
  payNew(data: any): void
  paymentInProgress: boolean
  form3ds: I3DsForm
  formFingerprint?: IFingerprintForm
  paymentError: any
  setPaymentError(err: string): void
  checkPaymentStatus(data: { id: number }): void
  payMobile(data: Record<string, number | string>): Promise<void>
  initPaymentMobile: (data: initPaymentPayload) => Promise<AxiosResponse>
  riskDisclosureAcceptedError: string
  setRiskDisclosureAcceptedError: (error: string) => void
  isKycRejected: boolean
}

type TProcessingError = (error: OrderProcessingError) => void

interface IUsePayment {
  order: IOrder
  nextStep: (step: string) => void
  setServerError: (err: string) => void
  setPaymentData: (paymentData: any) => void
  setBilling: (needBilling: boolean) => void
  setProcessingError: TProcessingError
  termsAccepted: boolean
  onProviderError?: (error: string) => void
  kycRequired?: boolean
  setIsLoadForm?: (loadForm: boolean) => void
}

export default function usePayment({
  order,
  nextStep,
  setServerError,
  setPaymentData,
  setBilling,
  setProcessingError,
  termsAccepted,
  onProviderError,
  kycRequired,
  setIsLoadForm,
}: IUsePayment): IPaymentHook {
  const [paymentError, setPaymentError] = useState('')
  const [riskDisclosureAcceptedError, setRiskDisclosureAcceptedError] =
    useState('')
  const [isKycRejected, setIsKycRejected] = useState(false)
  const [form3ds, setForm3ds] = useState<I3DsForm>({} as I3DsForm)
  const [formFingerprint, setFormFingerprint] = useState<
    IFingerprintForm | undefined
  >()
  const [processing, setProcessing] = useState(false)
  const paymentStepRef = useRef<PaymentNext | undefined>()
  const paymentIdRef = useRef<number | undefined>()
  const [checkPaymentStatus] = usePaymentStatusApi((res) => {
    if (res.success) {
      if (res.data.nextStep !== paymentStepRef.current) {
        proceedPaymentResult(res)
        return
      }
      if (
        [PaymentNext.ThreeDSV2, PaymentNext.Fingerprint].includes(
          res.data.nextStep
        )
      ) {
        setTimeout(
          () => checkPaymentStatus({ id: paymentIdRef.current }),
          10000
        )
      }
    } else {
      setTimeout(() => checkPaymentStatus({ id: paymentIdRef.current }), 10000)
    }
  })

  const proceedPaymentResult = ({
    data: { nextStep: paymentNextStep, form, fingerprintForm },
  }: IPayResponse) => {
    paymentStepRef.current = paymentNextStep

    switch (paymentNextStep) {
      case PaymentNext.ThreeDSV2:
        setForm3ds(form as I3DsForm)
        setFormFingerprint(undefined)
        break
      case PaymentNext.Fingerprint:
        setFormFingerprint(fingerprintForm)
        break
      case PaymentNext.Verification:
        setFormFingerprint(undefined)
        setForm3ds({} as I3DsForm)
        nextStep('verification')
        break
      default:
        setProcessing(false)
        setFormFingerprint(undefined)
        setForm3ds({} as I3DsForm)
        if (paymentNextStep.toUpperCase() === 'ERROR') nextStep('error')
        if (!kycRequired && paymentNextStep.toUpperCase() === 'SUCCESS') {
          nextStep('success')
        }
    }
  }

  const [{ data: cards }, loadingCards, loadCards] = useCardsList()

  useEffect(() => {
    const storageCcn = window.localStorage.getItem('ccn')
    const findId = cards.find(
      (card: any) =>
        card.number.startsWith(storageCcn?.slice(0, 4)) &&
        card.saved &&
        card.number.endsWith(storageCcn?.slice(-4))
    )
    if (findId) {
      window.localStorage.setItem('cardId', String(findId.id))
      window.localStorage.removeItem('ccn')
    }
  }, [cards])

  const [payNew, payingNew] = usePrivatePaymentPayNew((res) => {
    if (res.success) {
      setProcessing(true)
      setPaymentError('')
      proceedPaymentResult(res)
      loadCards()
    } else {
      setPaymentData({})
      if (process.env.REACT_APP_SENTRY_URL) {
        Sentry.captureException(res.error, {
          tags: { section: 'Payment Request' },
        })
      }
      const { type, message } = res.error
      if (
        type === 'CONSTRAINT_VIOLATION' &&
        message === 'Card already exists'
      ) {
        setPaymentError('cardExist')
      } else if (type === 'CARD_NOT_SUPPORTED') {
        setPaymentError('cardNotSupported')
      } else if (type === 'CARD_PROCESSING_ERROR') {
        setPaymentError('cardProcessingError')
      } else {
        setPaymentError('somethingWentWrong')
      }
    }
  })
  const [pay, paying] = usePrivatePaymentPay((res: any) => {
    if (res.success) {
      setProcessing(true)
      setPaymentError('')
      proceedPaymentResult(res)
    } else if (
      res.error.type === 'CONSTRAINT_VIOLATION' &&
      res.error.message === 'Card already exists'
    ) {
      setPaymentError('cardExist')
    } else if (res.error.type === 'CARD_NOT_SUPPORTED') {
      setPaymentError('cardNotSupported')
    } else {
      setPaymentError('somethingWentWrong')
    }
  })
  const [payMobile, payingMobile] = usePrivatePaymentMobile((res: any) => {
    if (res.success) {
      setProcessing(true)
      setPaymentError('')
      proceedPaymentResult(res)
    } else {
      setPaymentError('somethingWentWrong')
    }
  })

  const [get3ds, getting3ds] = useThreeDSGet((res: any) => {
    if (res.success) {
      setForm3ds(res.data.form)
    } else if (res.error.type === 'ORDER_ALREADY_PROCESSED') {
      setServerError('ORDER_ALREADY_PROCESSED')
    } else {
      setServerError('somethingWentWrong')
    }
  })

  const initPaymentCallback = (res: Record<string, any>) => {
    if (res.success) {
      setBilling(false)
      setPaymentData(res.data)
      paymentIdRef.current = res.data.id
      if (res.data.nextStep === PaymentNext.ThreeDS) {
        get3ds({ id: res.data.id })
      }
      setIsLoadForm && setIsLoadForm(false)
    } else if (res.error) {
      setIsLoadForm && setIsLoadForm(false)
      const { type } = res.error
      switch (type) {
        case 'GEODATA_NOT_RECOGNIZED':
          return setBilling(true)
        case 'COUNTRY_NOT_SET':
          return
        case 'PROVIDER_ERROR':
          return (
            onProviderError?.('PROVIDER_ERROR_SF') ||
            setPaymentError('chooseAnotherProvider')
          )
        case OrderProcessingError.SUSPICIOUS_ORDER:
          if (res.error?.details[0]?.domain) {
            return (
              onProviderError?.('DISPOSABLE_DOMAIN') ||
              setPaymentError('chooseAnotherProvider')
            )
          }
          if (res.error?.details?.[0]?.age) {
            return setIsKycRejected(true)
          }
          return setProcessingError(OrderProcessingError.SUSPICIOUS_ORDER)
        case 'PROVIDER_DISABLED':
          return setServerError(type)
        case 'TOC_NOT_ACCEPTED':
          if (res.error?.details?.[0]?.riskDisclosure) {
            return setRiskDisclosureAcceptedError(
              res.error.details[0].riskDisclosure
            )
          }
          return setServerError('somethingWentWrong')
        default:
          setServerError('somethingWentWrong')
      }
    }
  }

  const [initPayment, initingPayment] = useWaterfallPaymentInit(
    `${order.id}`,
    termsAccepted,
    initPaymentCallback
  )

  const [initPaymentMobile, initingPaymentMobile] =
    useWaterfallPaymentInitMobile(
      `${order.id}`,
      termsAccepted,
      initPaymentCallback
    )

  return {
    initPayment,
    pay,
    payNew,
    paymentInProgress:
      initingPayment ||
      paying ||
      payingNew ||
      getting3ds ||
      processing ||
      initingPaymentMobile ||
      payingMobile,
    form3ds,
    formFingerprint,
    paymentError,
    setPaymentError,
    checkPaymentStatus,
    payMobile,
    initPaymentMobile,
    riskDisclosureAcceptedError,
    setRiskDisclosureAcceptedError,
    isKycRejected,
  }
}
