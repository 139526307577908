import React from 'react'

import { useTranslation } from 'react-i18next'

import clsx from 'clsx'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useGlobalStyles from '@/hooks/useGlobalStyles'
import successIcon from '@/icons/resultIcons/success.svg'

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01em',
    maxWidth: '380px',
  },
  content: {
    'padding': '0px',
    '&:first-child': {
      padding: '0px',
    },
    'marginBottom': theme.spacing(6),
  },
  icon: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.5,
    letterSpacing: '0.01em',
    marginBottom: theme.spacing(3),
  },
  actionsButtons: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  button: {
    width: '100%',
    margin: '0px',
    height: '52px',
    maxWidth: '100%',
  },
  actions: {
    padding: '0px',
    display: 'block',
  },
  modal: {
    width: '449px',
    padding: '42px 24px 26px',
    background: '#fff',
    borderRadius: 8,
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down(515)]: {
      width: '100%',
    },
  },
}))

interface IExportHistoryModal {
  isOpen: boolean
  href: string
  onClose: () => void
  exportedFileName: string
}

function ExportHistoryModal({
  href,
  isOpen,
  onClose,
  exportedFileName,
}: IExportHistoryModal) {
  const { t } = useTranslation()

  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <DialogContent className={classes.content}>
          <div className={classes.icon}>
            <img src={successIcon} />
          </div>
          <Typography variant="h6" align="center" className={classes.title}>
            {t('settlements.reportTitle')}
          </Typography>

          <Typography
            variant="body1"
            align="center"
            className={classes.subtitle}
          >
            {t('settlements.reportSubtitle')}
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing={true} className={classes.actions}>
          <div className={classes.actionsButtons}>
            <Button
              className={clsx(globalClasses.button, classes.button)}
              color="primary"
              component="a"
              variant="contained"
              download={`${exportedFileName}.zip`}
              href={href}
              onClick={onClose}
              id="return-settlement"
            >
              {t('Download report')}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  )
}
export default ExportHistoryModal
