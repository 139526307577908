import { IOrder } from '@/api/order'
import backgroundOBI from '@/config/backgroundOBI.json'
import { WIIDGET_ENV } from '@/helpers/constants'

export function getIsOBIBackground(
  order: IOrder,
  isShowApmView: boolean,
  isBackgroundShown = false
) {
  return (
    order?.mId &&
    order?.apm === 'OBI' &&
    isShowApmView &&
    !isBackgroundShown &&
    backgroundOBI.showForMerchants[
      WIIDGET_ENV as keyof typeof backgroundOBI.showForMerchants
    ]?.includes(order.mId)
  )
}
